import React from "react";
import styles from "./styles.module.css";
import { Link } from "gatsby";
import Button from "../../../../components/Button";

export default function Organisez() {
  return (
    <div className={styles.container}>
      
        <h1>Calendriers et carnets d'adresses</h1>

        <div className="organisezLinks">
          <Link to="/developpez/services-web/solutions-pour-le-cybercommerce/forfaits-cybercommerce/">Courriel classe affaires</Link>
          <Link to="/developpez/services-web/forfaits-courriels/organisez/">Organisez</Link>
          <Link to="/developpez/services-web/forfaits-courriels/synchronisez/">Synchronisez</Link>
          <Link to="/developpez/services-web/hebergement-web/">Forfaits Web</Link>
          <Link to="/developpez/services-web/forfaits-courriels/">Forfaits courriel</Link>
        </div>

        <p>Avez-vous déjâ eu à fixer un rendez-vous avec une collègue absente du bureau? Ou essayé d'organiser une réunion pour vous retrouver avec une chaîne de courriels sans fin, seulement pour arrêter la date qui convient le mieux à tout le monde? Ou encore avez-vous déjâ cherché longuement les coordonnées d'un fournisseur alors que vous savez que votre collègue les a?</p>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Améliorez les communications et l'organisation</h2>
            </div>
            <div className="organisezBlockBody">
                <p>La collaboration donne à des collègues la possibilité de consulter la disponibilité de chacun, de planifier et programmer les réunions, et permet aux directeurs de suivre étroitement les opérations quotidiennes.</p>
            </div>
        </div>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Planifiez vos rendez-vous et consultez votre agenda n'importe où et en tout temps</h2>
            </div>
            <div className="organisezBlockBody">
                <p>Le calendrier et le carnet d'adresses SyncSuite vous permettent une planification facile et efficace des rendez-vous en vous donnant accès à différents calendriers personnels et partagés de même qu’au vaste carnet d’adresses de l’entreprise. Soyez toujours connecté et à jour aussi bien au bureau que sur la route en accédant à différents tableaux horaires n'importe où n'importe quand par le biais de Webmail, Outlook, Outlook Express, BlackBerry ainsi que d'autres dispositifs sans fil adaptés à SyncML.</p>
            </div>
        </div>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Interagissez avec vos collègues, visualisez et programmez réunions, événements ou rappels</h2>
            </div>
            <div className="organisezBlockBody">
                <p>Planifiez les réunions en utilisant les calendriers partagés et les assistants de planification. Partagez vos horaires ou échéanciers! Autorisez les organiseurs personnels à accéder à votre calendrier afin qu’ils puissent se mettre à jour. Comme les entrées aux calendriers sont synchronisées sur tous les dispositifs, vous serez averti des dates et réunions importantes et de tout changement alors même que vous êtes en déplacement.</p>
            </div>
        </div>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Partagez, publiez et s'inscrivez-vous à plusieurs calendriers</h2>
            </div>
            <div className="organisezBlockBody">
                <p>Établissez et gérez des horaires ou plans de travail de groupe, identifiez facilement les classes de calendrier par les thèmes couleur qui leur sont attribués. Déterminez le niveau d'accès que vous autorisez pour chaque usager : ajout, effacement, lecture seule ou stricte consultation des périodes libres ou occupées.</p>
            </div>
        </div>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Invitez vos collègues aux réunions</h2>
            </div>
            <div className="organisezBlockBody">
                <p>Envoyez des invitations aux réunions par des courriels automatiques.</p>
            </div>
        </div>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Caractéristiques générales du Carnet d’adresses</h2>
            </div>
            <div className="organisezBlockBody">
                <p>Carnets d’adresses personnels et partagés<br/><br/>
                    Répertoires de contacts personnels<br/>
                    Répertoires de contacts collectifs<br/>
                    Fonction perfectionnée de profil des contacts<br/>
                    Remplissage automatique des noms de contacts<br/>
                    Ajout rapide de contacts</p>
            </div>
        </div>

        <div className="organisezBlock">
            <div className="organisezBlockHead">
                <h2>Caractéristiques générales du Calendrier</h2>
            </div>
            <div className="organisezBlockBody">
                <p>Accessibilité aux calendriers personnels ou partagés<br/><br/>
                    Accueil d’un nombre illimité de calendriers<br/>
                    Correspondance des niveaux d’accès des utilisateurs aux calendriers partagés selon différents paramètres d’autorisation : ajout, effacement, modification ou lecture seule des entrées, ou stricte consultation des périodes libres et occupées<br/>
                    Visualisation des calendriers par jour, semaine ou mois<br/>
                    Recherche avancée d’événements par titre, calendrier particulier, laps de temps (date de début/date de fin)<br/>
                    Identifiant couleur pour chaque calendrier</p>
            </div>
        </div>

    </div>
  );
}
