import React from "react";
import Layout from "../../../../../other/layout";
import Organisez from "../../../../../components/AC-StaticPages/Developpez/ServicesWeb/ForfaitsCourriels/Organisez/Organisez";
import SEO from "../../../../../other/seo";

export default function organisez() {
  return (
    <Layout>
      <SEO title="Organisez" />
      <Organisez />
    </Layout>
  );
}
